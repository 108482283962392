import * as classes from '../components/widget/widget.css';
import { SCRIPT_TAG_ATTRIBUTES } from '../interfaces';
import { cl } from '../utils/concat-classes';
import { getAttributeFromScriptTag } from '../utils/get-attribute';

export const getCookieUrlText = (): string => {
  const cookieFromAttribute = getAttributeFromScriptTag(
    SCRIPT_TAG_ATTRIBUTES.COOKIE_URL,
  );
  const cookieUrl = cookieFromAttribute ? cookieFromAttribute : detectCookie();
  return cookieUrl || '';
};

export const detectCookie = (): string => {
  const links = [...document.querySelectorAll('a')];
  const keywords = ['cookies', 'cookie', 'legal'];

  let policyUrlText = '';

  for (const keyword of keywords) {
    const policyLink = links.find((link) =>
      link.href.toLowerCase().includes(keyword.toLowerCase()),
    );
    if (policyLink) {
      policyUrlText = `By clicking "Accept All" you consent to our <a href="${
        policyLink.href
      }" class="${cl(
        classes.link,
        classes.blue,
      )}" target='_blank'>Cookie Policy</a>.`;
      break;
    }
  }

  return policyUrlText;
};
