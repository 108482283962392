import { get } from './api/fetch';
import { renderWidget } from './components';
import { createElementOverride } from './core/create-element-override';
import { acceptHandler, rejectHandler } from './core/handlers';
import { getValue, setValue } from './core/local-storage';
import { observer } from './core/observer';
import '@webcomponents/custom-elements/custom-elements.min.js';
import {
  ACCEPTED_LOCALSTORAGE_KEYS,
  COOKIE_CONSENT_STATUS,
  GetSitePreferencesResponse,
  SCRIPT_TAG_ATTRIBUTES,
  SCRIPT_SRC_PARAMS,
  UserPreferencesData,
  UserConsent,
} from './interfaces';
import './core/document-cookies-override';
import './core/document-methods-override';
import { getAttributeFromScriptTag } from './utils/get-attribute';
import { getUrlParam } from './utils/get-url-param';
import { isEu } from './utils/is-eu';

declare global {
  interface Window {
    UserConsent: UserConsent;
  }
}

const initUserConsent = () => {
  if (!window.UserConsent) {
    window.UserConsent = {} as UserConsent;
    window.UserConsent.blockedScripts = [];
    window.UserConsent.onaccept = acceptHandler;
    window.UserConsent.onreject = rejectHandler;
  }
};

const setupMutationObserver = () => {
  const userConsent = window.UserConsent;
  userConsent.mutationObserver = observer;
  userConsent.mutationObserver.observe(document.documentElement, {
    childList: true,
    subtree: true,
  });
};

export const getAccountId = () => {
  const accountID: string | null =
    getAttributeFromScriptTag(SCRIPT_TAG_ATTRIBUTES.DATA_ACCOUNT) ||
    getUrlParam(SCRIPT_SRC_PARAMS.ACCOUNT);

  if (!accountID) {
    console.log(
      'Error: Account ID is not set. Could not fetch User Preferences from API',
    );
    return {};
  }

  return accountID;
};

const fetchUserPreferences = async (): Promise<UserPreferencesData> => {
  const accountID = getAccountId();

  const API_BASE = import.meta.env.VITE_API_BASE;
  try {
    const response = await get<GetSitePreferencesResponse>(
      `${API_BASE}/config/${accountID}`,
    );

    const sitePreferences: UserPreferencesData = response.payload;

    return sitePreferences;
  } catch (error) {
    console.error('There was an error fetching user preferences', error);
  }

  return {};
};

const setupStyles = async (): Promise<void> => {
  const stylesFromScript: string =
    getAttributeFromScriptTag(SCRIPT_TAG_ATTRIBUTES.DATA_STYLES) || '{}';

  try {
    const userPreferences: UserPreferencesData = await fetchUserPreferences();

    const finalStyles: UserPreferencesData = stylesFromScript
      ? { ...JSON.parse(stylesFromScript), ...userPreferences }
      : userPreferences;

    setValue(ACCEPTED_LOCALSTORAGE_KEYS.USER_PREFERENCES, finalStyles);
  } catch (error) {
    console.error('There was an error setting up styles', error);
  }
};

(() => {
  if (!isEu()) {
    return;
  }
  initUserConsent();
  setupMutationObserver();
  window.addEventListener(
    'load',
    async () => {
      observer.disconnect();

      const cookieConsent: COOKIE_CONSENT_STATUS | null = getValue(
        ACCEPTED_LOCALSTORAGE_KEYS.COOKIE_CONSENT_STATUS,
      );

      if (!cookieConsent) {
        await setupStyles();
        renderWidget();
      } else if (cookieConsent === COOKIE_CONSENT_STATUS.ACCEPTED_ALL) {
        acceptHandler();
      }
    },
    {
      once: true,
    },
  );
  document.createElement = createElementOverride;
})();
