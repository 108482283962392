import { logo } from './public/userconsent-logo';
import * as classes from './widget.css';
import { getValue } from '../../core/local-storage';
import {
  ACCEPTED_LOCALSTORAGE_KEYS,
  UserPreferencesData,
} from '../../interfaces';
import { theme } from '../../system/theme.css';
import { cl } from '../../utils/concat-classes';
import { button } from '../button/button';

export const widgetElement = (policyText: string) => {
  const userPreferences: UserPreferencesData =
    getValue(ACCEPTED_LOCALSTORAGE_KEYS.USER_PREFERENCES) || {};

  const markup = `<div id='wrapper'
    class='${cl(
      theme,
      classes.flex,
      classes.flex_wrap,
      classes.justify_spread,
      classes.items_center,
      classes.gap_x_20,
      classes.fixed,
      classes.z_max,
      classes.wrapper,
    )}'>
        <div class='${cl(
          classes.flex,
          classes.flex_col,
          classes.gap_10,
          classes.flex_1,
        )}'>
            <span class='${cl(
              classes.text_l,
              classes.text_bold,
              classes.block,
            )}'><bdi>Your privacy is important</bdi></span>
            <span class='${cl(classes.text_m, classes.grey)}'><bdi>
            We use cookies to enhance your browsing experience and deliver personal content. ${policyText}</bdi></span>
        </div>
        <div class='${cl(
          classes.flex,
          classes.flex_wrap,
          classes.justify_spread,
          classes.gap_20,
          classes.gap_x_16,
          classes.items_center,
          classes.buttonsWrapper,
        )}'>
            ${logo}
            <div id="buttons" class='${cl(
              classes.flex,
              classes.gap_20,
              classes.buttons,
            )}'>
            ${button({
              title: 'Accept Necessary',
              name: 'accept necessary',
              variant: 'outlined',
              color: userPreferences?.color,
            })}
            ${button({
              title: 'Accept All',
              name: 'accept all',
              color: userPreferences?.color,
            })}
            </div>
        </div>
    </div>`;
  return markup;
};
