import { createElementBackup } from './create-element-override';
import { setValue } from './local-storage';
import {
  ACCEPTED_LOCALSTORAGE_KEYS,
  COOKIE_CONSENT_STATUS,
} from '../interfaces';

export const acceptHandler = () => {
  document.createElement = createElementBackup;
  setValue(
    ACCEPTED_LOCALSTORAGE_KEYS.COOKIE_CONSENT_STATUS,
    COOKIE_CONSENT_STATUS.ACCEPTED_ALL,
  );
  window.UserConsent.blockedScripts.forEach((script) => {
    script.type = 'text/javascript';
    const tagParent = script.parentNode;
    const nextElement = script.nextElementSibling;
    tagParent.removeChild(script);
    tagParent.insertBefore(script, nextElement);
  });

  window.UserConsent.blockedScripts = [];
};

export const rejectHandler = () => {
  setValue(
    ACCEPTED_LOCALSTORAGE_KEYS.COOKIE_CONSENT_STATUS,
    COOKIE_CONSENT_STATUS.REJECTED_ALL,
  );
};
