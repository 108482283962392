import { ACCEPTED_LOCALSTORAGE_KEYS, LocalStorageData } from '../interfaces';

export function setValue<K extends ACCEPTED_LOCALSTORAGE_KEYS>(
  key: K,
  value: LocalStorageData[K],
): void {
  const formattedValue: string = JSON.stringify(value);
  localStorage.setItem(key, formattedValue);
}

export function getValue<K extends ACCEPTED_LOCALSTORAGE_KEYS>(
  key: K,
): LocalStorageData[K] | null {
  try {
    const item: string | null = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (error) {
    console.error('Error while parsing local storage item', error);
    return null;
  }
}
