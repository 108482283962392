import { acceptHandler, rejectHandler } from './core/handlers';

/* LOCAL STORAGE DATA */
export enum COOKIE_CONSENT_STATUS {
  ACCEPTED_ALL = 'accepted-all',
  REJECTED_ALL = 'rejected-all',
  NOT_SET = 'not-set',
}

export enum ACCEPTED_LOCALSTORAGE_KEYS {
  COOKIE_CONSENT_STATUS = 'cookie-consent-status',
  AUTH_TOKEN = 'x-auth-token',
  USER_PREFERENCES = 'user-preferences',
}

export interface UserPreferencesData {
  [key: string]: any;
}

export interface LocalStorageData {
  [ACCEPTED_LOCALSTORAGE_KEYS.COOKIE_CONSENT_STATUS]: COOKIE_CONSENT_STATUS;
  [ACCEPTED_LOCALSTORAGE_KEYS.AUTH_TOKEN]: string;
  [ACCEPTED_LOCALSTORAGE_KEYS.USER_PREFERENCES]: UserPreferencesData;
}

/* FETCH */
export interface GetSitePreferencesResponse {
  payload: UserPreferencesData;
  payloadType: 'SiteConfigPayload';
}

/* SCRIPT TAG ATTRIBUTES */
export enum SCRIPT_TAG_ATTRIBUTES {
  DATA_ACCOUNT = 'data-user-consent-account',
  DATA_STYLES = 'data-styles',
  DATA_SITE_ID = 'data-site-id',
  COOKIE_URL = 'data-cookie-url',
}

/* SCRIPT SRC PARAMS */
export enum SCRIPT_SRC_PARAMS {
  ACCOUNT = 'account',
}

/* BLOCKING SCRIPT */
export const BLOCKED_SCRIPT_TYPE_ATTRIBUTE = 'javascript/blocked' as const;

export const BLACK_LIST = [
  'google-analytics.com',
  'googleadservices.com',
  'googlesyndication.com',
  'googleoptimize.com',
  'facebook.',
  'doubleclick.net',
  'hotjar.com',
  'cdn.mxpnl.com',
  'snap.licdn.com',
  'crazyegg.com',
  'twitter.com',
  'adobedtm.com',
  'pinimg.com',
  'doubleclick.net',
  'youtube.com',
  'youtube-nocookie.com',
  'linkedin.com',
  'addthis.com',
  'bing.com',
  'sharethis.com',
  'yahoo.com',
  'addtoany.com',
  'dailymotion.com',
  'amazon-adsystem.com',
  'tiktok.com',
] as const;

export interface UserConsent {
  blockedScripts: any[];
  mutationObserver: MutationObserver;
  onaccept: typeof acceptHandler;
  onreject: typeof rejectHandler;
}
