import { checkPatternInScript } from './check-cookie-code';
import { getValue } from './local-storage';
import {
  COOKIE_CONSENT_STATUS,
  ACCEPTED_LOCALSTORAGE_KEYS,
  BLOCKED_SCRIPT_TYPE_ATTRIBUTE,
} from '../interfaces';
import { isOnBlackList } from '../utils/is-on-black-list';

export const createElementBackup = document.createElement.bind(document);

const originalDescriptors = {
  src: Object.getOwnPropertyDescriptor(HTMLScriptElement.prototype, 'src'),
  type: Object.getOwnPropertyDescriptor(HTMLScriptElement.prototype, 'type'),
};

export const createElementOverride = function (...args: any[]): HTMLElement {
  if (args[0].toLowerCase() !== 'script') {
    return createElementBackup(...(args as [string, ...any[]]));
  }

  const scriptElt = createElementBackup(
    ...(args as [string, ...any[]]),
  ) as HTMLScriptElement;

  const shouldBlockScript = (): boolean => {
    const isBlacklisted = scriptElt.src
      ? isOnBlackList(scriptElt.src)
      : checkPatternInScript(scriptElt.textContent);

    return isBlacklisted;
  };

  const hasNoConsent = (): boolean => {
    const hasNoConsent =
      getValue(ACCEPTED_LOCALSTORAGE_KEYS.COOKIE_CONSENT_STATUS) !==
      COOKIE_CONSENT_STATUS.ACCEPTED_ALL;

    return hasNoConsent;
  };

  // Define getters / setters to ensure that the script type is properly set
  try {
    Object.defineProperties(scriptElt, {
      src: {
        ...originalDescriptors.src,
        set(value) {
          if (shouldBlockScript() && hasNoConsent()) {
            originalDescriptors.type?.set?.call(
              this,
              BLOCKED_SCRIPT_TYPE_ATTRIBUTE,
            );
          }
          originalDescriptors.src?.set?.call(this, value);
        },
      },
      type: {
        ...originalDescriptors.type,
        get() {
          const typeValue = originalDescriptors.type?.get?.call(this);
          if (
            (hasNoConsent() && shouldBlockScript()) ||
            typeValue === BLOCKED_SCRIPT_TYPE_ATTRIBUTE
          ) {
            // Prevent script execution.
            return null;
          }
          return typeValue;
        },
        set(value) {
          const typeValue =
            shouldBlockScript() && hasNoConsent()
              ? BLOCKED_SCRIPT_TYPE_ATTRIBUTE
              : value;
          originalDescriptors.type?.set?.call(this, typeValue);
        },
      },
    });

    scriptElt.setAttribute = function (name, value) {
      if (name === 'type' || name === 'src') {
        scriptElt[name] = value;
      } else {
        HTMLScriptElement.prototype.setAttribute.call(scriptElt, name, value);
      }
    };
  } catch (error) {
    console.log(error);
    return createElementBackup(...(args as [string, ...any[]]));
  }
  return scriptElt;
};
