import { checkPatternInScript } from './check-cookie-code';
import { getValue } from './local-storage';
import {
  ACCEPTED_LOCALSTORAGE_KEYS,
  COOKIE_CONSENT_STATUS,
  BLOCKED_SCRIPT_TYPE_ATTRIBUTE,
} from '../interfaces';
import { isOnBlackList } from '../utils/is-on-black-list';

function isScriptElement(node: Node): node is HTMLScriptElement {
  return node.nodeName === 'SCRIPT';
}

const checkIfScriptMightSetsCookies = <T extends Node>(newChild: T): void => {
  if (isScriptElement(newChild)) {
    const userHasGivenConsent: boolean =
      getValue(ACCEPTED_LOCALSTORAGE_KEYS.COOKIE_CONSENT_STATUS) ===
      COOKIE_CONSENT_STATUS.ACCEPTED_ALL;

    if (userHasGivenConsent) {
      newChild.type = 'text/javascript';
      return;
    }

    const isScriptOnBlackList: boolean = isOnBlackList(newChild.src);
    if (
      isScriptOnBlackList ||
      checkPatternInScript(newChild.textContent || '')
    ) {
      if (!window.UserConsent.blockedScripts.includes(newChild)) {
        newChild.type = BLOCKED_SCRIPT_TYPE_ATTRIBUTE;
        window.UserConsent.blockedScripts.push(newChild);
      }
    }
  }
};

const overrideAppendMethods = (): void => {
  const originalAppendChild = Element.prototype.appendChild;
  Element.prototype.appendChild = function <T extends Node>(newChild: T): T {
    checkIfScriptMightSetsCookies(newChild);
    return originalAppendChild.call(this, newChild) as T;
  };

  const originalInsertBefore = Element.prototype.insertBefore;
  Element.prototype.insertBefore = function <T extends Node>(
    node: T,
    child: Node | null,
  ): T {
    checkIfScriptMightSetsCookies(node);
    return originalInsertBefore.call(this, node, child) as T;
  };

  const originalreplaceChild = Element.prototype.replaceChild;
  Element.prototype.replaceChild = function <T extends Node>(
    node: Node,
    child: T,
  ): T {
    checkIfScriptMightSetsCookies(node);
    return originalreplaceChild.call(this, node, child) as T;
  };
};

(() => {
  overrideAppendMethods();
})();
