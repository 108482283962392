export interface ShadowDomElementOptions {
  mode?: ShadowRootMode;
  styles?: string;
  stylesLink?: string;
  fontFaces?: string;
  render: (wrapper: HTMLDivElement) => void;
}

export const createShadowDomElement = ({
  mode = 'closed',
  styles,
  stylesLink,
  fontFaces,
  render,
}: ShadowDomElementOptions) => {
  return class ShadowDomElement extends HTMLElement {
    private shadow: ShadowRoot;

    constructor() {
      super();

      this.shadow = this.attachShadow({ mode });
      this.attachStyles({ styles, stylesLink, fontFaces });
      this.appendWrapper(render);
    }

    private attachStyles({
      styles,
      stylesLink,
      fontFaces,
    }: {
      styles?: string;
      stylesLink?: string;
      fontFaces?: string;
    }) {
      let style: HTMLLinkElement | HTMLStyleElement | null = null;

      if (stylesLink) {
        const stylesLinkElement = document.createElement('link');
        stylesLinkElement.type = 'text/css';
        stylesLinkElement.rel = 'stylesheet';
        stylesLinkElement.href = stylesLink;
        style = stylesLinkElement;
      }

      if (styles) {
        style = document.createElement('style');
        style.textContent = styles;
      }

      if (!style) {
        console.warn(
          'No styles provided for createShadowDomElement factory method. Provide "styles" or "stylesLink".',
        );
        return;
      }

      this.injectFontFaces(fontFaces || '');
      this.shadow.appendChild(style);
    }

    private appendWrapper(render: ShadowDomElementOptions['render']) {
      const wrapper = document.createElement('div');
      render(wrapper);
      this.shadow.appendChild(wrapper);
    }

    private injectFontFaces(fontFaces: string) {
      if (!document.getElementById('injectedFonts')) {
        const head = document.head || document.getElementsByTagName('head')[0],
          style = document.createElement('style');
        style.id = 'injectedFonts';
        style.innerText = fontFaces;
        head.appendChild(style);
      }
    }
  };
};

export const defineShadowDomElement = ({
  name,
  elementConstructor,
}: {
  name: string;
  elementConstructor: CustomElementConstructor;
}) => {
  customElements.define(name, elementConstructor);
};
