import { handleInlineScript } from './handle-inline-script';
import { BLOCKED_SCRIPT_TYPE_ATTRIBUTE } from '../interfaces';
import { isOnBlackList } from '../utils/is-on-black-list';

export const observer = new MutationObserver((mutations) => {
  for (let i = 0; i < mutations.length; i++) {
    const addedNodes = mutations[i].addedNodes;
    for (let j = 0; j < addedNodes.length; j++) {
      const node = addedNodes[j];
      if (node.nodeType === Node.ELEMENT_NODE && node.nodeName === 'SCRIPT') {
        const scriptNode = node as HTMLScriptElement;
        const src = scriptNode.src;
        if (src) {
          if (isOnBlackList(src)) {
            window.UserConsent.blockedScripts.push(scriptNode);
            scriptNode.type = BLOCKED_SCRIPT_TYPE_ATTRIBUTE;
          }
        } else {
          handleInlineScript(scriptNode);
        }
      }
    }
  }
});
