import { acceptHandler, rejectHandler } from './handlers';
import { handleClickStat } from '../core/handle-click-stat';

export const handleButtonClick = (e: Event) => {
  const target = e.target as HTMLButtonElement;
  switch (target.name) {
    case 'accept all':
      acceptHandler();
      handleClickStat('accept');
      handleClickStat('stop');
      break;
    case 'accept necessary':
      rejectHandler();
      handleClickStat('denied');
      handleClickStat('stop');
      break;
  }
};
