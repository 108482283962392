import * as classes from './button.css';
import { changeColorBasedOnVariant } from '../../utils/change-btn-colors';

export interface ButtonProps {
  title: string;
  classes?: Record<string, string>;
  name?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'contained' | 'outlined';
  color?: string;
}

export const button = ({
  title,
  type = 'button',
  variant = 'contained',
  name,
  color,
}: ButtonProps) => {
  return `<button name='${name}' type='${type}' title='${title}' class='${classes.root(
    {
      [variant]: true,
    },
  )}'
    style='${changeColorBasedOnVariant(color, variant)}'
  >
          ${title}
          </button>`;
};
