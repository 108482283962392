import MetropolisBold from '../../assets/fonts/metropolis/Metropolis-Bold.woff2';
import MetropolisExtraBold from '../../assets/fonts/metropolis/Metropolis-ExtraBold.woff2';
import MetropolisLight from '../../assets/fonts/metropolis/Metropolis-Light.woff2';
import MetropolisMedium from '../../assets/fonts/metropolis/Metropolis-Medium.woff2';
import MetropolisRegular from '../../assets/fonts/metropolis/Metropolis-Regular.woff2';
import MetropolisSemiBold from '../../assets/fonts/metropolis/Metropolis-SemiBold.woff2';

export const fontFaces = `@font-face {
  font-family: 'MetropolisRegular';
  src: url('${MetropolisRegular}') format('woff2');
}

@font-face {
  font-family: 'MetropolisMedium';
  src: url('${MetropolisMedium}') format('woff2');
}

@font-face {
  font-family: 'MetropolisExtraBold';
  src: url('${MetropolisExtraBold}') format('woff2');
}

@font-face {
  font-family: 'MetropolisLight';
  src: url('${MetropolisLight}') format('woff2');
}

@font-face {
  font-family: 'MetropolisBold';
  src: url('${MetropolisBold}') format('woff2');
}
@font-face {
  font-family: 'MetropolisSemiBold';
  src: url('${MetropolisSemiBold}') format('woff2');
}`;
