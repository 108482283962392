import { getValue } from './local-storage';
import {
  ACCEPTED_LOCALSTORAGE_KEYS,
  COOKIE_CONSENT_STATUS,
} from '../interfaces';

(() => {
  const originalDocumentCookieDescriptor = Object.getOwnPropertyDescriptor(
    Document.prototype,
    'cookie',
  );

  Object.defineProperty(document, 'cookie', {
    configurable: true,
    enumerable: true,
    get: function () {
      return originalDocumentCookieDescriptor?.get?.call(this);
    },
    set: function (value) {
      const cookieProperties: string[] = value.split(';');

      const domain: string | undefined = cookieProperties.filter(
        (property: string) => property.includes('domain'),
      )[0];

      if (domain) {
        const [, domainValue]: string[] = domain.split('=');
        const cookieDomain =
          domainValue[0] === '.' ? domainValue.slice(1) : domainValue;
        const currentPageDomain = window.location.hostname;

        const parsedCookieDomain = new URL('http://' + cookieDomain);
        const parsedCurrentPageDomain = new URL('http://' + currentPageDomain);

        if (parsedCookieDomain.hostname === parsedCurrentPageDomain.hostname) {
          // This is a first-party cookie
          originalDocumentCookieDescriptor?.set?.call(this, value);
        } else {
          // This is a third-party cookie
          const cookieConsent: COOKIE_CONSENT_STATUS | null = getValue(
            ACCEPTED_LOCALSTORAGE_KEYS.COOKIE_CONSENT_STATUS,
          );

          if (cookieConsent === COOKIE_CONSENT_STATUS.ACCEPTED_ALL) {
            originalDocumentCookieDescriptor?.set?.call(this, value);
          }
        }
      } else {
        // should be a first-party cookie
        originalDocumentCookieDescriptor?.set?.call(this, value);
      }
    },
  });
})();

export {};
