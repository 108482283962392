export const changeColorBasedOnVariant = (
  color: string | undefined,
  variant: string,
) => {
  if (!color) return '';

  if (variant === 'contained') {
    return `background-color: ${color}; border-color: ${color};`;
  }
  return `border-color: ${color}; color: ${color};`;
};
