export const logo = `<svg width="130px" height="24px" direction="ltr" viewBox="0 0 130 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="MVP" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="usw_admin_GDPR" transform="translate(-993.000000, -277.000000)">
            <g id="Group-32" transform="translate(0.000000, 244.000000)">
                <g id="Group" transform="translate(994.000000, 33.000000)">
                    <path d="M11.9572262,0.96 C18.2968681,0.96 23.4361633,6.11767968 23.4361633,12.48 C23.4361633,18.8423203 18.2968681,24 11.9572262,24 C5.61758426,24 0.478289047,18.8423203 0.478289047,12.48 L0.646907735,12.475111 C2.15333385,12.3875144 3.34802333,11.1337736 3.34802333,9.6 L3.34315177,9.43077812 L3.33528665,9.34964314 C3.78767733,9.5117301 4.27501238,9.6 4.78289047,9.6 C7.16025619,9.6 9.0874919,7.66587012 9.0874919,5.28 C9.0874919,4.77068237 8.9996668,4.28195078 8.83650963,3.8291376 C8.91921635,3.83632916 9.00292697,3.84 9.0874919,3.84 C10.6724024,3.84 11.9572262,2.55058008 11.9572262,0.96 Z" id="Combined-Shape" fill="#A29BA9"></path>
                    <ellipse id="Oval" stroke="#FFFFFF" stroke-width="1.44" cx="16.9792612" cy="10.32" rx="1.91315619" ry="1.92"></ellipse>
                    <ellipse id="Oval" stroke="#FFFFFF" stroke-width="1.44" cx="6.21775762" cy="15.36" rx="1.19572262" ry="1.2"></ellipse>
                    <ellipse id="Oval" stroke="#FFFFFF" stroke-width="1.44" cx="14.8269605" cy="16.8" rx="1" ry="1"></ellipse>
                    <line x1="0" y1="5.28" x2="3.82631238" y2="5.28" id="Path" stroke="#A29BA9" stroke-width="1.44" stroke-linecap="round"></line>
                    <line x1="-0.00684381076" y1="5.28" x2="3.83315619" y2="5.28" id="Path" stroke="#A29BA9" stroke-width="1.44" stroke-linecap="round" transform="translate(1.913156, 5.280000) rotate(90.000000) translate(-1.913156, -5.280000) "></line>
                    <ellipse id="Oval" fill="#FFFFFF" cx="10.7615036" cy="12.72" rx="1" ry="1"></ellipse>
                    <ellipse id="Oval" fill="#FFFFFF" cx="11.0006481" cy="20.16" rx="1" ry="1"></ellipse>
                    <ellipse id="Oval" fill="#FFFFFF" cx="20.566429" cy="14.4" rx="1" ry="1"></ellipse>
                    <ellipse id="Oval" fill="#FFFFFF" cx="16.7401167" cy="5.76" rx="1" ry="1"></ellipse>
                    <ellipse id="Oval" fill="#FFFFFF" cx="11.9572262" cy="6.72" rx="1" ry="1"></ellipse>
                    <ellipse id="Oval" fill="#A29BA9" cx="6.21775762" cy="0.96" rx="1" ry="1"></ellipse>
                    <text id="USERCONSENT" font-family="MetropolisExtraBold, MetropolisRegular" font-size="13" font-weight="700" letter-spacing="-0.185714293" fill="#796E84">
                        <tspan x="31.1639663" y="16.56">USER</tspan>
                        <tspan x="65.9241091" y="16.56" font-family="MetropolisLight, MetropolisRegular" font-weight="300">CONSENT</tspan>
                    </text>
                </g>
            </g>
        </g>
    </g>
</svg>`;
