import { findWidgetScript } from './find-widget-script';

export const getUrlParam = (param: string): string | null => {
  const scriptTag: HTMLScriptElement | null = findWidgetScript();
  const src = scriptTag?.src;
  if (src) {
    const url = new URL(src);
    const params = new URLSearchParams(url.search);
    return params.get(param) || null;
  }

  return null;
};
