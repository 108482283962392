import { BLACK_LIST } from '../interfaces';

//Covers cookie, sessionStorage, localStorage, indexedDB, and caches
// const combinedCookiePattern =
//   /document\.cookie\s*(?:=|\s)|localStorage\.(?:setItem|getItem|removeItem|clear)\s*\(|sessionStorage\.(?:setItem|getItem|removeItem|clear)\s*\(|indexedDB\.(?:open|deleteDatabase)\s*\(|caches\.(?:open|match|has|delete|keys)\s*\(/i;

export const checkPatternInScript = (scriptContent: string | null): boolean => {
  //This functionality was removed for MVP, cause it blocks both necessary and non-necessary scripts, which can lead to a site fail. It will be improved and added back in the future.
  // if (scriptContent) {
  //   const match = combinedCookiePattern.exec(scriptContent);
  //   if (match) {
  //     return true;
  //   }
  // }
  // return false;
  for (let i = 0; i < BLACK_LIST.length; i++) {
    if (scriptContent?.includes(BLACK_LIST[i])) {
      return true;
    }
  }
  return false;
};
