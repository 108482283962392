import { fontFaces } from './fonts';
import { widgetElement } from './widget/widget';
import {
  createShadowDomElement,
  defineShadowDomElement,
} from '../core/create-shadow-dom-element';
import { getCookieUrlText } from '../core/get-cookie-url';
import { handleButtonClick } from '../core/handle-button-click';
import { handleClickStat, siteId } from '../core/handle-click-stat';

export const renderWidget = () => {
  const userConsentWidgetElement = () =>
    createShadowDomElement({
      stylesLink: `${import.meta.env.VITE_BASE_URL}/style.css`,
      fontFaces,
      render: (wrapper) => {
        wrapper.innerHTML = widgetElement(getCookieUrlText());

        const buttonsWrapper =
          wrapper.querySelector<HTMLDivElement>('#buttons');
        buttonsWrapper?.addEventListener('click', (e) => {
          wrapper.style.visibility = 'hidden';
          handleButtonClick(e);
          document.removeEventListener('visibilitychange', visibilityHandler);
          window.removeEventListener('unload', unloadHandler);
        });

        const API_BASE = import.meta.env.VITE_API_BASE;

        const unloadHandler = () => {
          const body = {
            operation: 'stop',
            local_ts: new Date().toISOString(),
          };
          navigator.sendBeacon(
            `${API_BASE}/stop/${siteId}`,
            JSON.stringify(body),
          );
        };

        const visibilityHandler = () => {
          if (document.visibilityState === 'hidden') {
            const body = {
              operation: 'stop',
              local_ts: new Date().toISOString(),
            };
            navigator.sendBeacon(
              `${API_BASE}/stop/${siteId}`,
              JSON.stringify(body),
            );
          }
        };

        document.addEventListener('visibilitychange', visibilityHandler);

        window.addEventListener('unload', unloadHandler);

        handleClickStat('start');
      },
    });

  defineShadowDomElement({
    name: 'user-consent',
    elementConstructor: userConsentWidgetElement(),
  });

  document.body.prepend(document.createElement('user-consent'));
};
