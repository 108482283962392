import { post, get } from '../api/fetch';

const API_BASE = import.meta.env.VITE_API_BASE;

export let siteId: string;

export const handleClickStat = async (operation: string): Promise<void> => {
  try {
    if (operation === 'start') {
      await get(
        `${API_BASE}/config/IPPiklyxFX/boomstream.com?operation=${operation}&local_ts=${new Date().toISOString()}`,
      ).then((res) => {
        if ((res as any)?.payload?.siteId) {
          siteId = (res as any).payload.siteId;
        } else {
          console.error('siteId not found in response');
        }
      });
    } else {
      if (!siteId) {
        console.error('siteId not set');
      } else {
        await post(`${API_BASE}/click/${siteId}`, {
          operation,
          local_ts: new Date().toISOString(),
        });
      }
    }
  } catch (error) {
    console.error('Statistic posting error', JSON.parse(JSON.stringify(error)));
  }
};
