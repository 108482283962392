const sendRequest = <T>(
  url: string,
  method: string,
  body?: object,
): Promise<T> => {
  return fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }

    return Promise.reject(new Error(response.statusText));
  });
};

export const get = <T>(url: string): Promise<T> => {
  return sendRequest(url, 'GET');
};

export const put = <T>(url: string, body: object): Promise<T> => {
  return sendRequest(url, 'PUT', body);
};

export const post = <T>(url: string, body: object): Promise<T> => {
  return sendRequest(url, 'POST', body);
};
