import { SCRIPT_TAG_ATTRIBUTES } from '../interfaces';

export const getAttributeFromScriptTag = (
  attribute: SCRIPT_TAG_ATTRIBUTES,
): string | null => {
  const scriptTag: HTMLScriptElement | null = document.querySelector(
    `script[${attribute}]`,
  );
  if (scriptTag) {
    return scriptTag.getAttribute(attribute) as string;
  } else {
    return null;
  }
};
